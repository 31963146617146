<template>
	<div class="loading-gears">
		<svg class="gear gear1" viewBox="0 0 131 131" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M55.8867 0.640625H75.8867V16.6406C82.2715 17.9365 88.2148 20.4492 93.4629 23.9258L104.779 12.6084L118.922 26.751L107.604 38.0684C111.078 43.3164 113.592 49.2588 114.887 55.6416H130.889V75.6416H114.887C113.592 82.0244 111.078 87.9658 107.604 93.2139L118.922 104.532L104.779 118.675L93.4609 107.356C88.2129 110.832 82.2715 113.345 75.8867 114.641V130.641H55.8867V114.641C49.5039 113.345 43.5625 110.834 38.3145 107.358L26.998 118.675L12.8555 104.532L24.1719 93.2158C20.6953 87.9678 18.1836 82.0254 16.8867 75.6416H0.888672V55.6416H16.8867C18.1816 49.2568 20.6953 43.3145 24.1719 38.0654L12.8555 26.75L26.998 12.6084L38.3145 23.9238C43.5625 20.4482 49.5039 17.9365 55.8867 16.6406V0.640625ZM65.8887 96.1553C82.7422 96.1553 96.4043 82.4932 96.4043 65.6406C96.4043 48.7881 82.7422 35.126 65.8887 35.126C49.0371 35.126 35.375 48.7881 35.375 65.6406C35.375 82.4932 49.0371 96.1553 65.8887 96.1553Z" />
		</svg>
		<svg class="gear gear2" viewBox="0 0 131 131" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M55.8867 0.640625H75.8867V16.6406C82.2715 17.9365 88.2148 20.4492 93.4629 23.9258L104.779 12.6084L118.922 26.751L107.604 38.0684C111.078 43.3164 113.592 49.2588 114.887 55.6416H130.889V75.6416H114.887C113.592 82.0244 111.078 87.9658 107.604 93.2139L118.922 104.532L104.779 118.675L93.4609 107.356C88.2129 110.832 82.2715 113.345 75.8867 114.641V130.641H55.8867V114.641C49.5039 113.345 43.5625 110.834 38.3145 107.358L26.998 118.675L12.8555 104.532L24.1719 93.2158C20.6953 87.9678 18.1836 82.0254 16.8867 75.6416H0.888672V55.6416H16.8867C18.1816 49.2568 20.6953 43.3145 24.1719 38.0654L12.8555 26.75L26.998 12.6084L38.3145 23.9238C43.5625 20.4482 49.5039 17.9365 55.8867 16.6406V0.640625ZM65.8887 96.1553C82.7422 96.1553 96.4043 82.4932 96.4043 65.6406C96.4043 48.7881 82.7422 35.126 65.8887 35.126C49.0371 35.126 35.375 48.7881 35.375 65.6406C35.375 82.4932 49.0371 96.1553 65.8887 96.1553Z" />
		</svg>
	</div>
</template>

<script>

export default {
	name: 'LoadingGears',
}
</script>
<style scoped>
.loading-gears {
	position: relative;
	width: 100px;
	height: 100px;
}

.gear {
	position: absolute;
	width: 60px;
	height: 60px;
}

.gear1 {
	animation: spin 2.5s linear infinite;
	fill: var(--primary);
}

.gear2 {
	animation: spin 2.5s linear infinite 0.1704545455s reverse;
	width: 60px;
	height: 60px;
	top: 40px;
	left: 40px;
	fill: #17a2b8;
}

@keyframes spin {
	100% {
		transform: rotate(360deg);
	}
}

</style>
