<template>
	<div>
		<LoadingSpinner v-if="loading" />
		<div v-if="!loading && !generating && !generatedUrl" style="max-width: 720px; margin: 0 auto;">

			<h1 class="pagetitle">
				{{ $t('briefing.title') }}
			</h1>

			<div class="groups">
				<div v-for="group in groups" v-bind:key="group.key" class="group">
					<h2 class="group-title">
						{{ $t(`briefingReport.groups.${group.key}`) }}
						<font-awesome-icon icon="trash" @click="deleteGroup(group.key)" class="ml-1 del" />
					</h2>
					<div class="subgroups">
						<div v-for="subgroup in group.subgroups" v-bind:key="subgroup.key" class="subgroup">
							<h3 class="subgroup-title">
								{{ subgroup.localTitle }}
							</h3>
							<div class="actions">
								<font-awesome-icon icon="trash" @click="deleteSubgroup(group.key, subgroup.key)" class="del" />
							</div>
						</div>
					</div>
				</div>
				<div>
					<h2 class="group-title">
						{{ $t('briefing.routePlan') }}
						<font-awesome-icon icon="trash" @click="showRoutePlan = false" class="ml-1 del" v-if="showRoutePlan" />
					</h2>

					<div v-if="!showRoutePlan" @click="showRoutePlan = true" class="add-select">
						<font-awesome-icon icon="plus" />
						{{ $t('briefing.addRoutePlan') }}
					</div>

					<Dropdown class="select" v-model="selectedRoutePlanId" :options="routePlans" dataKey="id" optionValue="id" optionLabel="name" :placeholder="$t('briefing.selectRoutePlan')"  v-if="showRoutePlan"/>
				</div>
				<div>
					<h2 class="group-title">
						{{ $t('briefing.flightPlan') }}
						<font-awesome-icon icon="trash" @click="showFlightPlan = false" class="ml-1 del" v-if="showFlightPlan"  />
					</h2>

					<div v-if="!showFlightPlan" @click="showFlightPlan = true" class="add-select">
						<font-awesome-icon icon="plus" />
						{{ $t('briefing.addFlightPlan') }}
					</div>

					<Dropdown class="select" v-model="selectedFlightPlanId" :options="flightPlans" dataKey="id" optionValue="id" :optionLabel="formatFlightPlanLabel" :placeholder="$t('briefing.selectFlightPlan')"  v-if="showFlightPlan"></Dropdown>
				</div>
			</div>

			<div class="main-action">
				<button @click="print" class="btn btn-primary">
					<font-awesome-icon icon="print" />
					{{ $t('briefing.print') }}
				</button>
			</div>
		</div>

		<div v-if="generating && !generatedUrl" class="generating">
			<LoadingGears  />

			<div class="generating-text">
				<h1>{{$t('briefing.generating-title')}}</h1>
				<p class="m-0">{{ $t('briefing.generating') }}</p>
				<h6 class="text-muted m-0 mt-2">
					{{ $t('briefing.generating-info') }}
				</h6>
			</div>
		</div>

		<div v-if="generatedUrl" class="generated">
			<font-awesome-icon icon="check-circle" class="check" />
			<div>
				<h1>{{ $t('briefing.generated') }}</h1>
				<div>
					<a :href="generatedUrl" target="_blank" class="btn btn-primary">
						<font-awesome-icon icon="download"  />
						{{ $t('briefing.download') }}
					</a>
					<button @click="generatedUrl = null" class="btn btn-link">
						<font-awesome-icon icon="chevron-left" />
						{{ $t('briefing.back') }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import briefingmanager from '@/plugins/briefingmanager'
import LoadingSpinner from '@/components/LoadingSpinner.vue';

import Dropdown from 'primevue/dropdown';
import LoadingGears from "@/components/LoadingGears.vue";

export default {
	components: { LoadingGears, LoadingSpinner, Dropdown },
	name: 'BriefingView',
	data() {
		return {
			/** @type {BriefingGroup[]} */
			groups: [],
			/** @type {RoutePlan[]} */
			routePlans: [],
			/** @type {number|null} */
			selectedRoutePlanId: null,
			showRoutePlan: false,
			/** @type {FlightPlan[]} */
			flightPlans: [],
			/** @type {number|null} */
			selectedFlightPlanId: null,
			showFlightPlan: false,
			loading: true,
			generating: false,
			generatedUrl: null,
		}
	},
	beforeMount() {
		this.refreshGroups();
		Promise.all([this.loadRoutePlans(), this.loadFlightPlans()]).then(() => {
			this.loading = false;
		});
	},
	watch: {
		showRoutePlan(val) {
			if (!val) {
				this.selectedRoutePlan = null;
			}
		},
		selectedFlightPlanId(val) {
			if (!val) {
				this.selectedFlightPlan = null;
			}
		}
	},
	methods: {
		/**
		 * @param {FlightPlan} p
		 */
		formatFlightPlanLabel(p) {
			return `${p.registrationNumber} (${p.id}) - ${window.formatDateTime((p.modifiedAt || p.createdAt))}`
		},
		refreshGroups() {
			this.groups = briefingmanager.getGroups();
		},
		async print() {
			window.newEvent("PRINT_BRIEFING", {
				types: this.groups.map(g => g.key).join(";"),
				routePlan: this.selectedRoutePlanId,
				flightPlan: this.selectedFlightPlanId,
			})
			this.generating = true;
			const name = await this.$rest.generateBriefing(this.selectedRoutePlanId, this.selectedFlightPlanId);
			this.generating = false;
			this.generatedUrl = this.$rest.getBriefingPdfUrl(name);
		},
		deleteGroup(groupKey) {
			briefingmanager.deleteGroup(groupKey);
			this.refreshGroups();
		},
		deleteSubgroup(groupKey, subgroupKey) {
			briefingmanager.deleteSubgroup(groupKey, subgroupKey);
			this.refreshGroups();
		},
		async loadRoutePlans() {
			this.routePlans = await this.$rest.getRoutePlansForUser() || [];
		},
		async loadFlightPlans() {
			const flightPlans = await this.$rest.getFlightPlansForUser();
			this.flightPlans = [...flightPlans];
		}
	}
}

</script>

<style>

.del {
	cursor: pointer;
	color: red;
}

.pagetitle {
	font-size: 1.5rem;
	font-weight: bold;
	margin-bottom: 1rem;
}

.groups {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.group {
}

.group-title {
	font-size: 0.7rem;
	font-weight: bold;
	margin: 0 0 0.5rem 0;
	text-transform: uppercase;
}

.subgroups {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.subgroup {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.5rem 0.7rem;
	border: 1px solid #ccc;
	border-radius: 10px;
	gap: 0.5rem;
}

.subgroup-title {
	font-size: 0.8rem;
	font-weight: bold;
	margin: 0;
}

.add-select {
	display: inline-flex;
	cursor: pointer;
	padding: 0.5rem 0.7rem;
	border: 2px solid var(--primary);
	border-radius: 10px;
	font-size: 0.8rem;
	font-weight: bold;
	justify-content: center;
	align-items: center;
	transition: all 0.2s;
	gap: 0.3rem;
}
.add-select:hover {
	scale: 1.01;
}
.add-select:active {
	scale: 0.95;
}

.select {
	width: 100%;
	border-radius: 10px;
}
.select:focus {
	box-shadow: none;
}

.select .p-dropdown-label {
	font-size: 0.8rem;
	font-weight: bold;
	padding: 0.7rem 0.7rem;
}

.p-dropdown:not(.p-disabled).p-focus {
	box-shadow: none;
}


.main-action {
	display: flex;
	justify-content: center;
	margin-top: 1rem;
}

.main-action .btn {
	font-size: 0.9rem;
	font-weight: bold;
	padding: 0.7rem 1rem;
	border-radius: 10px;
	width: 100%;
}

.generating {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	padding: 2rem 0;
}

.generating-text {
	text-align: center;
}

.generating-text h1 {
	font-size: 1.5rem;
	font-weight: bold;
}

.generating-text p {
	font-size: 1rem;
}


.generated {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1.5rem;
	padding: 2rem 0;
}

.generated > div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1rem;
}

.generated > div > div {
	display: flex;
	flex-direction: column;
	gap: 0;

}

.generated .check {
	font-size: 4rem;
	color: var(--primary);
}

.generated a {
	font-size: 1rem;
	font-weight: bold;
}

.generated h1 {
	font-size: 1.4rem;
	font-weight: bold;
	margin: 0;
}
</style>
